import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/methodology',
    name: 'methodology',
    redirect: '/faqs',
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/CalculatorHome.vue'),
    props: true,
  },
  {
    path: '/questionnaire',
    name: 'questionnaire',
    component: () => import('../views/CalculatorQuestionnaire.vue'),
    props: true,
  },
  {
    path: '/region',
    name: 'region',
    component: () => import('../views/CalculatorRegion.vue'),
    props: true,
  },
  {
    path: '/travel',
    name: 'travel',
    component: () => import('../views/results/tips/TipsTravel.vue'),
    props: true,
  },
  {
    path: '/results',
    component: () => import('../views/CalculatorResults.vue'),
    props: true,
    // beforeEnter: (to, from, next) => {
    //   if (store.state.completion == false) {
    //     next(false);
    //   } else {
    //     next();
    //   }
    // },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'footprint',
        component: () => import('../views/results/ResultsFootprint.vue'),
        props: true,
      },
      {
        path: 'travel',
        component: () => import('../views/results/tips/TipsTravel.vue'),
        props: true,
      },
      {
        path: 'home',
        component: () => import('../views/results/tips/TipsHome.vue'),
        props: true,
      },
      {
        path: 'stuff',
        component: () => import('../views/results/tips/TipsStuff.vue'),
        props: true,
      },
      {
        path: 'food',
        component: () => import('../views/results/tips/TipsFood.vue'),
        props: true,
      },
    ],
  },
  {
    path: '/stay-in-touch',
    name: 'stayintouch',
    component: () => import('../views/StayInTouch.vue'),
    props: true,
  },
  {
    path: '/faqs',
    name: 'faqs',
    component: () => import('../views/FAQs.vue'),
    props: true,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
  },
  {
    path: '/404',
    name: 'Error404',
    component: () => import('../views/Error404NotFound.vue'),
  },
  {
    path: '/completed',
    name: 'completed',
    component: () => import('../components/questionnaire/CompletedQuestionnaire.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  },
});

export default router;
