import { createApp } from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserSecret, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import {
  faXTwitter, faFacebookF, faInstagram, faYoutube, faLinkedinIn, faTiktok, faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import axios from 'axios';
import * as Sentry from '@sentry/vue';
import VueCookies from 'vue-cookies';
import router from './router';
import store from './store';
import App from './App.vue';
import './index.css';

// All core and brand fontawesome icons
library.add(
  faUserSecret,
  faEnvelope,
  faXTwitter,
  faFacebookF,
  faInstagram,
  faYoutube,
  faLinkedinIn,
  faTiktok,
  faWhatsapp,
);

axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').content;

if (import.meta.env.VITE_BACKEND_API_TOKEN !== undefined) {
  axios.defaults.headers.common.Authorization = `Bearer ${import.meta.env.VITE_BACKEND_API_TOKEN}`;
}

window.APP_URL = import.meta.env.VITE_BACKEND_API;
const myV3App = createApp(App);
myV3App
  .component('font-awesome-icon', FontAwesomeIcon);

Sentry.init({
  app: myV3App,
  dsn: import.meta.env.VITE_SENTRY_DSN_VUE,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],

  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/wwf-footprint-vue\.localdev/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

myV3App
  .use(store)
  .use(router);
// add more functionality to myV3App

// now we're ready to mount
myV3App.mount('#app');

myV3App.use(VueCookies);
