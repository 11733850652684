// import Vue from 'vue';
import { createStore } from 'vuex';

// Vue.use(Vuex);

const store = createStore({
  state: {
    completion: false,
    region: '',
    stored_postcode: '',
    section_navigation: {
      food: { title: 'Food', current: 0, length: 0 },
      travel: { title: 'Travel', current: 0, length: 0 },
      home: { title: 'Home', current: 0, length: 0 },
      stuff: { title: 'Stuff', current: 0, length: 0 },
    },
  },
  mutations: {
    // setCompletion(boolean) {
    //   this.state.completion = boolean;
    // },
  },
  actions: {
  },
  getters: {
  },
});
export default store;
