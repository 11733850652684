<template>
    <div id="app">
        <div class="page-content" role="main">
        <div class="bg-black text-3xl py-6 wwf-title hidden sm:block">
          <div class="max-w-r-max mx-8 xl:mx-auto">
        <a href="https://wwf.org.uk/" class="text-link transition xl:px-24">
        Go to wwf.org.uk
        </a>
        </div>
        </div>
          <div class="max-w-r-max mx-8 xl:px-24 xl:mb-24 xl:mx-auto">
            <router-view/>
          </div>
    </div>
    </div>
</template>

<style lang="scss">
@tailwind base;
@tailwind components;
@tailwind utilities;

    @import 'assets/css/breakpoint';
    @import 'assets/css/mixins';
    //@import 'assets/css/fonts';
    @import 'assets/css/variables';
    @import 'assets/css/animations';
    @import 'assets/css/typography';
    @import 'assets/css/icons';
    @import 'assets/css/buttons';

    // general
    @import 'assets/css/generic';
    @import 'assets/css/header';
    @import 'assets/css/footer';
    @import 'assets/css/home';

    // questionnaire
    @import 'assets/css/questionnaire/navigation';
    @import 'assets/css/questionnaire/hint';
    @import 'assets/css/questionnaire/form';
    @import 'assets/css/questionnaire/sections';
    @import 'assets/css/questionnaire/region';

    // results
    @import 'assets/css/results/results';
    @import 'assets/css/results/footprint';
    @import 'assets/css/results/breakdown';
    @import 'assets/css/results/contact';
    @import 'assets/css/results/mailing';
    @import 'assets/css/results/postcode';

    // libraries
    @import 'assets/css/libraries/leaflet';

</style>
